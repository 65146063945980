import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Breadcrumb from "../components/Breadcrumb"
import AuthorBlock, { AuthorBlockView } from "../components/AuthorBlock"
import ResultContent from "../components/ResultContent"
import Pagination from "../components/Pagination"
import { graphql } from "gatsby"
import Gql from "../typings/gql"
import { filterNull } from "../utils/typeguard";
import { POST_PER_PAGE } from "../constants";
import { AppContext } from "../utils/appContext";
import { getRoute } from "../utils/i18n"
import { useIntl } from "react-intl"

interface Props {
  pageContext: { slug: string, page: number } & AppContext;
  data: Gql.AuthorPageQuery;
}

const IndexPage: React.FC<Props> = ({ data, pageContext: { page, categories, mainMenu, mainSiteUrl } }) => {
  const { formatMessage: fm } = useIntl();

  if (!data.wordpressWpUsers) {
    return null;
  }
  
  const posts = data.wordpressWpUsers.authored_wordpress__POST?.slice((page - 1) * POST_PER_PAGE, page * POST_PER_PAGE) || [];
  const pageCount = Math.ceil((data.wordpressWpUsers.authored_wordpress__POST?.length || 0) / POST_PER_PAGE);
  
  return (
    <Layout categories={categories} mainMenu={mainMenu} mainSiteUrl={mainSiteUrl}>
      <SEO />
      
      <div className="main bg-gray">

        <Breadcrumb breadcrumb={[{ url: `/${getRoute("authors")}`, title: fm({ id: "breadcrumb.authors" }) }, { url: `/${getRoute("author")}/${data.wordpressWpUsers.slug}`, title: data.wordpressWpUsers.name || "" }]} />

        <AuthorBlock view={AuthorBlockView.Subpage} author={data.wordpressWpUsers} />
        
        {posts.filter(filterNull).map(post =>
          <ResultContent key={post.slug || ""} post={post} />
        )}

        <Pagination pageCount={pageCount} currentPage={page} url={`/${getRoute("author")}/${data.wordpressWpUsers?.slug}`} />
        
      </div>
      
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query AuthorPage($slug: String!) {
    wordpressWpUsers(slug: { eq: $slug }) {
      id
      avatar_urls {
        wordpress_96
      }
      description
      name
      slug
      authored_wordpress__POST {
        excerpt
        title
        slug
        featured_media {
          source_url
        }
      }
    }
  }
`;
