//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import cm from "./Breadcrumb.module.scss"
import { useIntl } from "react-intl";

interface BreadcrumbItem {
  url: string;
  title: string;
}

interface Props {
  breadcrumb: BreadcrumbItem[];
}

const Breadcrumb: React.FC<Props> = ({ breadcrumb }) => {
  const { formatMessage: fm } = useIntl();

  return (
    <div className={cm.breadcrumb}>
      <div className="row collapse">
        <div className="columns">
          <ul className={cm.navlist}>
            <li><Link to="/" >{fm({ id: "breadcrumb.index" })}</Link></li>
              {breadcrumb.map(({ url, title } , i) => 
                <>
                  {breadcrumb.length === i + 1 ? (
                    <li key={url}>{title}</li>
                  ):(
                    <li key={url}><Link to={url}>{title}</Link></li>
                  )}
                </>
              )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb
