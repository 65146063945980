//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"
import { Link, withPrefix } from "gatsby"
import cm from "./AuthorBlock.module.scss"
import Gql from "../typings/gql"
import { useIntl } from "react-intl"
import { getRoute } from "../utils/i18n"

export enum AuthorBlockView {
  AuthorsPage,
  Subpage,
  Post
}

interface Props {
  view: AuthorBlockView;
  author: Gql.AuthorsQuery["allWordpressWpUsers"]["nodes"][0];
}

const AuthorBlock: React.FC<Props> = ({ view, author }) => {
  const { formatMessage: fm } = useIntl();
  const avatarUrl = author.avatar_urls?.wordpress_96 || withPrefix("/images/avatar.jpg");
  return (
    <div className={`${cm.authorBlock} ${view === AuthorBlockView.Subpage ? cm.subpage : ""} ${view === AuthorBlockView.AuthorsPage ? cm.authorspage : ""}`}>
      <div className={cm.authorContainer}>
        <div className={cm.authorAvatar}>
          <div className={cm.authorImage}>
            <img src={avatarUrl} alt="" />
          </div>
        </div>
        <div className={cm.authorInfo}>
          <h3>{author.name}</h3>
          <p>
            {author.description}
          </p>
          {view !== AuthorBlockView.Subpage && <Link to={`/${getRoute("author")}/${author.slug}`} className="more">{fm({ id: "author.morearticles" })}</Link>}
          
        </div>
      </div>
    </div>
  )
}

export default AuthorBlock
