//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"
import { Link, withPrefix } from "gatsby"

import cm from "./ResultContent.module.scss"
import Gql from "../typings/gql"
import { getRoute } from "../utils/i18n"

interface Props {
    post: NotNull<NotNull<NotNull<Gql.AuthorPageQuery["wordpressWpUsers"]>["authored_wordpress__POST"]>[0]>;
    highlightWord?: string; // TODO <span className="orange">
}

const ResultContent: React.FC<Props> = ({ post }) => {
    const imgUrl = post.featured_media?.source_url || withPrefix("/images/cover-2.jpg");
  return (
    <Link to={`/${getRoute("article")}/${post.slug}`} className={cm.resultContent}>
        <div className="row collapse">
            <div className="columns medium-4">
                <div className={cm.resultImage}>
                    <img src={imgUrl} alt="" />
                </div>
            </div>
            <div className="columns medium-8">
                <div className={cm.text}>
                    <h3>{post.title}</h3>
                    <div className={cm.lead} dangerouslySetInnerHTML={{ __html: post.excerpt || "" }} />
                </div>
            </div>
        </div>
    </Link>
  )
}

export default ResultContent
